body {
  margin: 0;
  padding: 0;
}
.main-container {
  background: #f7f7f7;
  height: 100vh;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main-standard {
  width: 70%;
  padding: 20px;
}
.titling {
  font-size: 50px;
  font-weight: 700;
  line-height: 50px;
  margin-bottom: 10px;
  background: -webkit-linear-gradient(#dccec8, #464440);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.description-item {
  color: #9b9b9b;
  padding: 15px 0px;
}
.text-area-outer-lay {
  background-color: #ffff;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #e9e9e9;
}
.text-area-outer-lay textarea {
  border: none;
  width: 100%;
  display: block;
  overflow: auto;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  resize: none; /*remove the resize handle on the bottom right*/
  height: 200px;
  color: #504f4f;
}
.text-area-outer-lay p {
  font-weight: 500;
}
.flexme {
  display: flex;
}
.halfflex {
  width: 50%;;
}
input.display-hide {
  display: none;
}
.display-mac button {
  float: right;
}
button.plein-button {
  float: left;
  border: 0px;
  background: #fff;
  color: #cbcbcb;
  font-size: 12px;
  margin-right: 10px;
}
.show-count{
  margin-left: 10px;
  color: #cbcbcb;
  font-size: 12px;
  font-weight: 900 !important;
}
button.btn-faArrow {
  border: 0px;
  background: #aba5a3;
  padding: 5px 18px 7px;
  border-radius: 10px;
  color: #fff;
}
button.btn-faArrow:hover {
  background: #795e54;
}
.email-bot {
  float: right;
}
input.txt-settle {
  font-size: 13px;
  padding: 5px 10px;
  border: 0px;
  width: 220px;
}
input.email-text {
  margin-right: 10px;
}

img.logo-item {
  width: 100%;
}
.target-head {
  display: flex;
}
.logo-base {
  width: 35%;
  padding-top: 15px;
  padding-right: 25px;
}
.img-source {
  position: absolute;
  bottom: 11%;
  left: 35%;
}
.link-on {
  color: #c2552d;
  text-decoration: none;
  font-weight: 700;
}
@media screen and (max-width: 992px) {
    .main-standard {
      padding: 10px;
      width: 80%;
  }
  .titling {
    font-size: 25px;
    line-height: 30px;
  }
  .text-area-outer-lay p {
      padding-left: 8px;
  }
  .text-area-outer-lay textarea{
      padding-left: 10px;
  }
}
@media screen and (max-width: 480px) {
  .main-standard {
      padding: 0px;
      width: 90%;
  }
  .target-head {
      display: block;
      padding-top: 270px;
  }
  .email-bot {
      margin-bottom: 20px;
  }
  .flexme.attack-space .halfflex.display-mac {
      width: 30%;
  }
  .flexme.attack-space .halfflex {
      width: 65%;
  }
  .logo-base {
      padding: 25px;
      width: 100%;
      text-align: center;
  }
}